
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import DataInfo from '@/components/reports-v2/components/elements/DataInfo.vue';
import gdbx from '@/store/modules/gdbx';
import BaseFilterable from './BaseFilterable.vue';
import BaseFilterableContainer from './BaseFilterableContainer.vue';
import { addComma, roundToFixed } from '../../../../util/number';
import { FinancialPerformance } from '@/store/models.def';

@Component({
	components: { BaseFilterable, DataInfo },
})
export default class FinancialPerformanceTableFilterable extends BaseFilterableContainer {
	@Prop({ default: () => ({}) })
	public currentYearResult!: FinancialPerformance;
	@Prop({ default: () => ({}) }) public lastYearResult!: FinancialPerformance;
	@Prop({ default: () => ({}) }) public last2YearResult!: FinancialPerformance;
	@Prop({ default: () => ({}) }) public last3YearResult!: FinancialPerformance;
	@Prop({ default: () => ({}) }) public last4YearResult!: FinancialPerformance;

	public get currency() {
		return gdbx.currencySymbol;
	}

	public get numDecimal() {
		return gdbx.numDecimal;
	}

	// Current Year

	public get currentYear() {
		return this.currentYearResult.year;
	}
	public get currentYearRevenueGrowth() {
		return addComma(this.currentYearResult.revenueGrowth, this.numDecimal);
	}
	public get currentYearGrossMargin() {
		return addComma(this.currentYearResult.grossMargin, this.numDecimal);
	}
	public get currentYearNetMargin() {
		return addComma(this.currentYearResult.netMargin, this.numDecimal);
	}
	public get currentYearCashRatio() {
		return roundToFixed(this.currentYearResult.cashRatio, this.numDecimal);
	}
	public get currentYearCurrentRatio() {
		return addComma(this.currentYearResult.currentRatio, this.numDecimal);
	}
	public get currentYearDebtRatio() {
		return roundToFixed(this.currentYearResult.debtRatio, this.numDecimal);
	}
	public get currentYearReturnOnEquity() {
		return addComma(this.currentYearResult.returnOnEquity, this.numDecimal);
	}
	public get currentYearLeverageRatio() {
		return addComma(this.currentYearResult.leverageRatio, this.numDecimal);
	}
	public get currentYearFixedAssetToNetRatio() {
		return roundToFixed(
			this.currentYearResult.fixedAssetToNetRatio,
			this.numDecimal,
		);
	}
	public get currentYearEfficiencyRatio() {
		return roundToFixed(
			this.currentYearResult.efficiencyRatio,
			this.numDecimal,
		);
	}
	public get currentYearCurrentLiabilitiesToNetWorth() {
		return roundToFixed(
			this.currentYearResult.currentLiabilitiesToNetWorth,
			this.numDecimal,
		);
	}
	public get currentYearWorkingCapitalToTotalAsset() {
		return roundToFixed(
			this.currentYearResult.workingCapitalToTotalAsset,
			this.numDecimal,
		);
	}
	public get currentYearInventoryTurnover() {
		return roundToFixed(
			this.currentYearResult.inventoryTurnover,
			this.numDecimal,
		);
	}
	public get currentYearTotalAssetTurnover() {
		return roundToFixed(
			this.currentYearResult.totalAssetTurnover,
			this.numDecimal,
		);
	}
	public get currentYearAccountReceivableTurnover() {
		return roundToFixed(
			this.currentYearResult.accountReceivableTurnover,
			this.numDecimal,
		);
	}
	public get currentYearAccountPayableTurnover() {
		return roundToFixed(
			this.currentYearResult.accountPayableTurnover,
			this.numDecimal,
		);
	}

	// Last Year

	public get lastYear() {
		return this.lastYearResult.year;
	}
	public get lastYearRevenueGrowth() {
		return addComma(this.lastYearResult.revenueGrowth, this.numDecimal);
	}
	public get lastYearGrossMargin() {
		return addComma(this.lastYearResult.grossMargin, this.numDecimal);
	}
	public get lastYearNetMargin() {
		return addComma(this.lastYearResult.netMargin, this.numDecimal);
	}
	public get lastYearCashRatio() {
		return roundToFixed(this.lastYearResult.cashRatio, this.numDecimal);
	}
	public get lastYearCurrentRatio() {
		return addComma(this.lastYearResult.currentRatio, this.numDecimal);
	}
	public get lastYearDebtRatio() {
		return roundToFixed(this.lastYearResult.debtRatio, this.numDecimal);
	}
	public get lastYearReturnOnEquity() {
		return addComma(this.lastYearResult.returnOnEquity, this.numDecimal);
	}
	public get lastYearLeverageRatio() {
		return addComma(this.lastYearResult.leverageRatio, this.numDecimal);
	}
	public get lastYearFixedAssetToNetRatio() {
		return roundToFixed(
			this.lastYearResult.fixedAssetToNetRatio,
			this.numDecimal,
		);
	}
	public get lastYearEfficiencyRatio() {
		return roundToFixed(this.lastYearResult.efficiencyRatio, this.numDecimal);
	}
	public get lastYearCurrentLiabilitiesToNetWorth() {
		return roundToFixed(
			this.lastYearResult.currentLiabilitiesToNetWorth,
			this.numDecimal,
		);
	}
	public get lastYearWorkingCapitalToTotalAsset() {
		return roundToFixed(
			this.lastYearResult.workingCapitalToTotalAsset,
			this.numDecimal,
		);
	}
	public get lastYearInventoryTurnover() {
		return roundToFixed(this.lastYearResult.inventoryTurnover, this.numDecimal);
	}
	public get lastYearTotalAssetTurnover() {
		return roundToFixed(
			this.lastYearResult.totalAssetTurnover,
			this.numDecimal,
		);
	}
	public get lastYearAccountReceivableTurnover() {
		return roundToFixed(
			this.lastYearResult.accountReceivableTurnover,
			this.numDecimal,
		);
	}
	public get lastYearAccountPayableTurnover() {
		return roundToFixed(
			this.lastYearResult.accountPayableTurnover,
			this.numDecimal,
		);
	}

	// Last 2 year

	public get last2Year() {
		return this.last2YearResult.year;
	}
	public get last2YearRevenueGrowth() {
		return addComma(this.last2YearResult.revenueGrowth, this.numDecimal);
	}
	public get last2YearGrossMargin() {
		return addComma(this.last2YearResult.grossMargin, this.numDecimal);
	}
	public get last2YearNetMargin() {
		return addComma(this.last2YearResult.netMargin, this.numDecimal);
	}
	public get last2YearCashRatio() {
		return roundToFixed(this.last2YearResult.cashRatio, this.numDecimal);
	}
	public get last2YearCurrentRatio() {
		return addComma(this.last2YearResult.currentRatio, this.numDecimal);
	}
	public get last2YearDebtRatio() {
		return roundToFixed(this.last2YearResult.debtRatio, this.numDecimal);
	}
	public get last2YearReturnOnEquity() {
		return addComma(this.last2YearResult.returnOnEquity, this.numDecimal);
	}
	public get last2YearLeverageRatio() {
		return addComma(this.last2YearResult.leverageRatio, this.numDecimal);
	}
	public get last2YearFixedAssetToNetRatio() {
		return roundToFixed(
			this.last2YearResult.fixedAssetToNetRatio,
			this.numDecimal,
		);
	}
	public get last2YearEfficiencyRatio() {
		return roundToFixed(this.last2YearResult.efficiencyRatio, this.numDecimal);
	}
	public get last2YearCurrentLiabilitiesToNetWorth() {
		return roundToFixed(
			this.last2YearResult.currentLiabilitiesToNetWorth,
			this.numDecimal,
		);
	}
	public get last2YearWorkingCapitalToTotalAsset() {
		return roundToFixed(
			this.last2YearResult.workingCapitalToTotalAsset,
			this.numDecimal,
		);
	}
	public get last2YearInventoryTurnover() {
		return roundToFixed(
			this.last2YearResult.inventoryTurnover,
			this.numDecimal,
		);
	}
	public get last2YearTotalAssetTurnover() {
		return roundToFixed(
			this.last2YearResult.totalAssetTurnover,
			this.numDecimal,
		);
	}
	public get last2YearAccountReceivableTurnover() {
		return roundToFixed(
			this.last2YearResult.accountReceivableTurnover,
			this.numDecimal,
		);
	}
	public get last2YearAccountPayableTurnover() {
		return roundToFixed(
			this.last2YearResult.accountPayableTurnover,
			this.numDecimal,
		);
	}

	// Last 3 year

	public get last3Year() {
		return this.last3YearResult.year;
	}
	public get last3YearRevenueGrowth() {
		return addComma(this.last3YearResult.revenueGrowth, this.numDecimal);
	}
	public get last3YearGrossMargin() {
		return addComma(this.last3YearResult.grossMargin, this.numDecimal);
	}
	public get last3YearNetMargin() {
		return addComma(this.last3YearResult.netMargin, this.numDecimal);
	}
	public get last3YearCashRatio() {
		return roundToFixed(this.last3YearResult.cashRatio, this.numDecimal);
	}
	public get last3YearCurrentRatio() {
		return addComma(this.last3YearResult.currentRatio, this.numDecimal);
	}
	public get last3YearDebtRatio() {
		return roundToFixed(this.last3YearResult.debtRatio, this.numDecimal);
	}
	public get last3YearReturnOnEquity() {
		return addComma(this.last3YearResult.returnOnEquity, this.numDecimal);
	}
	public get last3YearLeverageRatio() {
		return addComma(this.last3YearResult.leverageRatio, this.numDecimal);
	}
	public get last3YearFixedAssetToNetRatio() {
		return roundToFixed(
			this.last3YearResult.fixedAssetToNetRatio,
			this.numDecimal,
		);
	}
	public get last3YearEfficiencyRatio() {
		return roundToFixed(this.last3YearResult.efficiencyRatio, this.numDecimal);
	}
	public get last3YearCurrentLiabilitiesToNetWorth() {
		return roundToFixed(
			this.last3YearResult.currentLiabilitiesToNetWorth,
			this.numDecimal,
		);
	}
	public get last3YearWorkingCapitalToTotalAsset() {
		return roundToFixed(
			this.last3YearResult.workingCapitalToTotalAsset,
			this.numDecimal,
		);
	}
	public get last3YearInventoryTurnover() {
		return roundToFixed(
			this.last3YearResult.inventoryTurnover,
			this.numDecimal,
		);
	}
	public get last3YearTotalAssetTurnover() {
		return roundToFixed(
			this.last3YearResult.totalAssetTurnover,
			this.numDecimal,
		);
	}
	public get last3YearAccountReceivableTurnover() {
		return roundToFixed(
			this.last3YearResult.accountReceivableTurnover,
			this.numDecimal,
		);
	}
	public get last3YearAccountPayableTurnover() {
		return roundToFixed(
			this.last3YearResult.accountPayableTurnover,
			this.numDecimal,
		);
	}

	// Year 4

	public get last4Year() {
		return this.last4YearResult.year;
	}
	public get last4YearRevenueGrowth() {
		return addComma(this.last4YearResult.revenueGrowth, this.numDecimal);
	}
	public get last4YearGrossMargin() {
		return addComma(this.last4YearResult.grossMargin, this.numDecimal);
	}
	public get last4YearNetMargin() {
		return addComma(this.last4YearResult.netMargin, this.numDecimal);
	}
	public get last4YearCashRatio() {
		return roundToFixed(this.last4YearResult.cashRatio, this.numDecimal);
	}
	public get last4YearCurrentRatio() {
		return addComma(this.last4YearResult.currentRatio, this.numDecimal);
	}
	public get last4YearDebtRatio() {
		return roundToFixed(this.last4YearResult.debtRatio, this.numDecimal);
	}
	public get last4YearReturnOnEquity() {
		return addComma(this.last4YearResult.returnOnEquity, this.numDecimal);
	}
	public get last4YearLeverageRatio() {
		return addComma(this.last4YearResult.leverageRatio, this.numDecimal);
	}
	public get last4YearFixedAssetToNetRatio() {
		return roundToFixed(
			this.last4YearResult.fixedAssetToNetRatio,
			this.numDecimal,
		);
	}
	public get last4YearEfficiencyRatio() {
		return roundToFixed(this.last4YearResult.efficiencyRatio, this.numDecimal);
	}
	public get last4YearCurrentLiabilitiesToNetWorth() {
		return roundToFixed(
			this.last4YearResult.currentLiabilitiesToNetWorth,
			this.numDecimal,
		);
	}
	public get last4YearWorkingCapitalToTotalAsset() {
		return roundToFixed(
			this.last4YearResult.workingCapitalToTotalAsset,
			this.numDecimal,
		);
	}
	public get last4YearInventoryTurnover() {
		return roundToFixed(
			this.last4YearResult.inventoryTurnover,
			this.numDecimal,
		);
	}
	public get last4YearTotalAssetTurnover() {
		return roundToFixed(
			this.last4YearResult.totalAssetTurnover,
			this.numDecimal,
		);
	}
	public get last4YearAccountReceivableTurnover() {
		return roundToFixed(
			this.last4YearResult.accountReceivableTurnover,
			this.numDecimal,
		);
	}
	public get last4YearAccountPayableTurnover() {
		return roundToFixed(
			this.last4YearResult.accountPayableTurnover,
			this.numDecimal,
		);
	}
}
