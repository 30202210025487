
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import StickyFilterContainer from '@/components/reports-v2/components/pages/StickyFilterContainer.vue';
import LastSync from '@/components/LastSync.vue';
import userx from '@/store/modules/userx';
import router from '@/router';
import moment from 'moment';
import DateFilter from '@/components/reports-v2/components/filters/DateFilter.vue';
import gdbx from '@/store/modules/gdbx';
// tslint:disable:max-line-length
import SelectFilter from '@/components/reports-v2/components/filters/SelectFilter.vue';
import CurrentRatioDisplayer from '@/components/reports-v2/components/codedWidgets/bp/CurrentRatioDisplayer.vue';
import DebtRatioDisplayer from '@/components/reports-v2/components/codedWidgets/bp/DebtRatioDisplayer.vue';
import CashRatioDisplayer from '@/components/reports-v2/components/codedWidgets/bp/CashRatioDisplayer.vue';
import FixedAssetNetWorthRatioDisplayer from '@/components/reports-v2/components/codedWidgets/bp/FixedAssetNetWorthRatioDisplayer.vue';
import CurrentLiabilitiesNetWorthRatioDisplayer from '@/components/reports-v2/components/codedWidgets/bp/CurrentLiabilitiesNetWorthRatioDisplayer.vue';
import WorkingCapitalTotalAssetsRatioDisplayer from '@/components/reports-v2/components/codedWidgets/bp/WorkingCapitalTotalAssetsRatioDisplayer.vue';
import FinancialPerformanceTable from '@/components/reports-v2/components/codedWidgets/bp/FinancialPerformanceTable.vue';

@Component({
	components: {
		StickyFilterContainer,
		DateFilter,
		LastSync,
		SelectFilter,
		CurrentRatioDisplayer,
		DebtRatioDisplayer,
		CashRatioDisplayer,
		FixedAssetNetWorthRatioDisplayer,
		CurrentLiabilitiesNetWorthRatioDisplayer,
		WorkingCapitalTotalAssetsRatioDisplayer,
		FinancialPerformanceTable,
	},
})
export default class FinancialRatio extends Vue {
	public selectedDay: number = moment().endOf('day').valueOf();

	public mounted() {
		this.track();
	}

	public track() {
		this.$ga.set('userId', userx.user!.uid + '_' + userx.user!.email);
		this.$ga.page(router);
	}

	public get selectedAsOfDate() {
		return moment(this.selectedDay).endOf('day').valueOf();
	}

	public get selectedDateRange(): [number, number] {
		return [
			moment(this.selectedDay).startOf('year').valueOf(),
			moment(this.selectedDay).endOf('year').valueOf(),
		];
	}

	public get selectedAgents() {
		return gdbx.allAgents;
	}

	public onHovering(value: boolean, filterId: string) {
		this.$emit('hovering', value, filterId);
	}
	public onEditing(value: boolean, filterId: string) {
		this.$emit('editing', value, filterId);
	}
}
