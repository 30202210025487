
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import BasicDisplayerFilterable from '@/components/reports-v2/components/filterables/BasicDisplayerFilterable.vue';
import BaseLineChart from '@/components/reports-v2/components/elements/charts/BaseLineChart.vue';
import moment from 'moment';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import { PermissionsGroup } from '@/store/models.def';
import gdbx from '@/store/modules/gdbx';
import { roundToFixed } from '@/util/number';
import * as echarts from 'echarts';

@Component({
	components: {
		BasicDisplayerFilterable,
		BaseLineChart,
	},
})
export default class FixedAssetNetWorthRatioDisplayer extends FilterWidget {
	public get permissionIds(): PermissionsGroup[] {
		return ['transactions'];
	}

	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
		| 'year'
	> = ['year'];

	public seriesData: number[] = [];
	public xAxisData: string[] = [];
	public totalAverage: number = 0;

	public get series(): echarts.ECharts[] {
		const ratio: echarts.ECharts | any = {
			name: 'Value',
			type: 'line',
			data: this.seriesData,
		};

		const result: echarts.ECharts[] = [ratio];
		return result;
	}

	public get expensiveHook() {
		const { selectedAsOfDate, selectedDateRange } = this;
		return JSON.stringify([selectedAsOfDate, selectedDateRange]);
	}

	public async expensiveCalc() {
		const assetRef = FilteredDatabase.ref('transactions').includes('accType', [
			'CA',
			'OA',
			'FA',
		]);

		const currentAssetRef = FilteredDatabase.ref('transactions').includes(
			'accType',
			['CA'],
		);

		const currentLiabilityRef = FilteredDatabase.ref('transactions').includes(
			'accType',
			['CL'],
		);

		const dateAsOf = this.selectedDateRange[0] - 1;

		const assetAsOfRef = assetRef.clone().dateAsOf(dateAsOf);
		const currentAssetAsOfRef = currentAssetRef.clone().dateAsOf(dateAsOf);
		const currentLiabilityAsOfRef = currentLiabilityRef
			.clone()
			.dateAsOf(dateAsOf);

		const assetMonthlyRef = assetRef.clone().dateRange(this.selectedDateRange);
		const currentAssetMonthlyRef = currentAssetRef
			.clone()
			.dateRange(this.selectedDateRange);
		const currentLiabilityMonthlyRef = currentLiabilityRef
			.clone()
			.dateRange(this.selectedDateRange);

		let pa = 0.3;
		let pb = 0;

		const monthlyAssets = await this._loadDimensionByPeriod(
			assetMonthlyRef,
			'month',
			pa,
			pb,
			'amount',
		);

		pb += pa;
		pa = 0.3;

		const monthlyCurrentLiabilities = await this._loadDimensionByPeriod(
			currentLiabilityMonthlyRef,
			'month',
			pa,
			pb,
			'amount',
		);

		pb += pa;
		pa = 0.3;

		const monthlyCurrentAssets = await this._loadDimensionByPeriod(
			currentAssetMonthlyRef,
			'month',
			pa,
			pb,
			'amount',
		);

		this.generateLoadingText(1);

		const currentMonth = moment().endOf('month');
		const isThisYear = this.selectedDateRange[1] > currentMonth.valueOf();
		const currentMonthIndex = isThisYear ? currentMonth.month() + 1 : 12;

		let asOfAsset = await (await assetAsOfRef.get()).getSum('amount');
		let asOfCurrentAsset = await (
			await currentAssetAsOfRef.get()
		).getSum('amount');
		let asOfCurrentLiability = await (
			await currentLiabilityAsOfRef.get()
		).getSum('amount');

		const results: number[] = [];
		for (let i = 0; i < currentMonthIndex; i++) {
			if (i >= currentMonthIndex) {
				results[i] = 0;
				continue;
			}
			asOfAsset += monthlyAssets[i].sum;
			asOfCurrentLiability += monthlyCurrentLiabilities[i].sum;
			asOfCurrentAsset += monthlyCurrentAssets[i].sum;
			results[i] =
				roundToFixed(asOfAsset) !== 0
					? roundToFixed(
							((asOfCurrentAsset - Math.abs(asOfCurrentLiability)) /
								asOfAsset) *
								100,
					  )
					: 0;
		}
		this.seriesData = results;

		this.xAxisData = monthlyAssets.map((dd) => dd.text);

		this.totalAverage = roundToFixed(
			this.seriesData.reduce((a, b) => a + b, 0) / currentMonthIndex,
			gdbx.numDecimal,
		);

		this.saveHistory('xAxisData', 'seriesData', 'totalAverage');
	}
}
