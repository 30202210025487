
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import FinancialPerformanceTableFilterable from '@/components/reports-v2/components/filterables/FinancialPerformanceTableFilterable.vue';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import {
	DimensionData,
	FinancialPerformance,
	DimensionMap,
	PermissionsGroup,
} from '@/store/models.def';
import gdbx from '@/store/modules/gdbx';
import moment, { Moment } from 'moment';

@Component({
	components: {
		FinancialPerformanceTableFilterable,
	},
})
export default class FinancialPerformanceTable extends FilterWidget {
	public get permissionIds(): PermissionsGroup[] {
		return ['transactions'];
	}

	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
		| 'year'
		| ''
	> = [''];

	public currentYearResult = {} as FinancialPerformance;
	public lastYearResult = {} as FinancialPerformance;
	public last2YearResult = {} as FinancialPerformance;
	public last3YearResult = {} as FinancialPerformance;
	public last4YearResult = {} as FinancialPerformance;

	public get numDecimal() {
		return gdbx.numDecimal;
	}

	public get currentDate() {
		return moment().valueOf();
	}

	public get isWithinCurrentFinancialYear() {
		return this.currentDate <= this.financialEndDateThisYear;
	}

	public get financialStartDateThisYear() {
		if (
			moment(gdbx.accountStartingDateThisYear).month() === 0 &&
			moment(gdbx.accountStartingDateThisYear).date() === 1
		) {
			return moment(gdbx.accountStartingDateThisYear).startOf('year').valueOf();
		} else {
			return moment(gdbx.accountStartingDateThisYear)
				.add(-1, 'year')
				.startOf('day')
				.valueOf();
		}
	}

	public get financialEndDateThisYear() {
		if (
			moment(gdbx.accountStartingDateThisYear).month() === 0 &&
			moment(gdbx.accountStartingDateThisYear).date() === 1
		) {
			return moment(gdbx.accountStartingDateThisYear).endOf('year').valueOf();
		} else {
			return moment(gdbx.accountStartingDateThisYear)
				.add(-1, 'day')
				.endOf('day')
				.valueOf();
		}
	}

	public get currentYearDateRange(): [number, number] {
		return [
			moment(this.financialStartDateThisYear)
				.add(this.isWithinCurrentFinancialYear ? 0 : 1, 'year')
				.valueOf(),
			moment(this.financialEndDateThisYear)
				.add(this.isWithinCurrentFinancialYear ? 0 : 1, 'year')
				.valueOf(),
		];
	}

	public get lastYearDateRange(): [number, number] {
		return [
			moment(this.financialStartDateThisYear)
				.add(this.isWithinCurrentFinancialYear ? -1 : 0, 'year')
				.valueOf(),
			moment(this.financialEndDateThisYear)
				.add(this.isWithinCurrentFinancialYear ? -1 : 0, 'year')
				.valueOf(),
		];
	}

	public get last2YearDateRange(): [number, number] {
		return [
			moment(this.financialStartDateThisYear)
				.add(this.isWithinCurrentFinancialYear ? -2 : -1, 'year')
				.valueOf(),
			moment(this.financialEndDateThisYear)
				.add(this.isWithinCurrentFinancialYear ? -2 : -1, 'year')
				.valueOf(),
		];
	}

	public get last3YearDateRange(): [number, number] {
		return [
			moment(this.financialStartDateThisYear)
				.add(this.isWithinCurrentFinancialYear ? -3 : -2, 'year')
				.valueOf(),
			moment(this.financialEndDateThisYear)
				.add(this.isWithinCurrentFinancialYear ? -3 : -2, 'year')
				.valueOf(),
		];
	}

	public get last4YearDateRange(): [number, number] {
		return [
			moment(this.financialStartDateThisYear)
				.add(this.isWithinCurrentFinancialYear ? -4 : -3, 'year')
				.valueOf(),
			moment(this.financialEndDateThisYear)
				.add(this.isWithinCurrentFinancialYear ? -4 : -3, 'year')
				.valueOf(),
		];
	}

	public get last5YearDateRange(): [number, number] {
		return [
			moment(this.financialStartDateThisYear)
				.add(this.isWithinCurrentFinancialYear ? -5 : -4, 'year')
				.valueOf(),
			moment(this.financialEndDateThisYear)
				.add(this.isWithinCurrentFinancialYear ? -5 : -4, 'year')
				.valueOf(),
		];
	}

	public getMonthValues(dateRange: [number, number]) {
		const [d0, d1] = dateRange.map((d) => moment(d));
		const period = d1.diff(d0, 'month') + 1;
		const result: Moment[] = [];
		for (let i = 0; i < period; i++) {
			result.push(d0.clone());
			d0.add(1, 'month');
		}
		return result;
	}

	public async getStockBalances(dateRange: [number, number]) {
		const stock = await FilteredDatabase.getStockMovement(dateRange);
		return stock;
	}

	// Base function

	public getDatas(datas: DimensionData[], filter?: string[]) {
		if (filter) {
			const result = datas.filter((dd) =>
				filter.includes(dd.value[0] as string),
			);
			return result.reduce((a, b) => a + b.sum, 0);
		}

		return datas.reduce((a, b) => a + b.sum, 0);
	}

	public getNetSales(datas: DimensionData[]) {
		return Math.abs(
			datas
				.filter((dd) => dd.value[0] === 'SL' || dd.value[0] === 'SA')
				.reduce((a, b) => a + b.sum, 0),
		);
	}

	public getOtherIncome(datas: DimensionData[]) {
		return Math.abs(
			datas
				.filter((dd) => dd.value[0] !== 'SL' && dd.value[0] !== 'SA')
				.reduce((a, b) => a + b.sum, 0),
		);
	}

	public async getCost(
		dateRange: [number, number],
		costDatas: DimensionData[],
	) {
		const cost = this.getDatas(costDatas);

		const stockBalance = await this.getStockBalances(dateRange);

		return cost - stockBalance;
	}

	public async getEquity(
		dateRange: [number, number],
		equityDatas: DimensionData[],
	) {
		const equity = equityDatas.reduce((a, b) => a + b.sum, 0);
		const stockBalance = await this.getStockBalances(dateRange);

		return equity + stockBalance;
	}

	public async getGrossProfit(
		dateRange: [number, number],
		incomeDatas: DimensionData[],
		costDatas: DimensionData[],
	) {
		const netSales = this.getNetSales(incomeDatas);
		const cost = await this.getCost(dateRange, costDatas);

		return netSales - cost;
	}

	public async getNetProfitBeforeTax(
		dateRange: [number, number],
		incomeDatas: DimensionData[],
		costDatas: DimensionData[],
		expenseDatas: DimensionData[],
	) {
		const grossProfit = await this.getGrossProfit(
			dateRange,
			incomeDatas,
			costDatas,
		);
		const otherIncome = this.getOtherIncome(incomeDatas);
		const expenses = this.getDatas(expenseDatas, ['EP']);

		return grossProfit + otherIncome - expenses;
	}

	// Ratio

	public getRevenueGrowth(
		currentDatas: DimensionData[],
		previousDatas: DimensionData[],
	) {
		const currentDD = this.getNetSales(currentDatas);
		const previousDD = this.getNetSales(previousDatas);

		return previousDD !== 0 ? ((currentDD - previousDD) / previousDD) * 100 : 0;
	}

	public async getGrossMargin(
		dateRange: [number, number],
		incomeDatas: DimensionData[],
		costDatas: DimensionData[],
	) {
		const grossProfit = await this.getGrossProfit(
			dateRange,
			incomeDatas,
			costDatas,
		);
		const netSales = await this.getNetSales(incomeDatas);

		return netSales !== 0 ? (grossProfit / netSales) * 100 : 0;
	}

	public async getNetMargin(
		dateRange: [number, number],
		incomeDatas: DimensionData[],
		costDatas: DimensionData[],
		expenseDatas: DimensionData[],
	) {
		const netProfitBeforeTax = await this.getNetProfitBeforeTax(
			dateRange,
			incomeDatas,
			costDatas,
			expenseDatas,
		);

		const netSales = this.getNetSales(incomeDatas);

		return netSales !== 0 ? (netProfitBeforeTax / netSales) * 100 : 0;
	}

	public getCashRatio(
		cashBankDatas: DimensionData[],
		currentLiabilityDatas: DimensionData[],
	) {
		const cashBank = this.getDatas(cashBankDatas);
		const liability = this.getDatas(currentLiabilityDatas);

		return liability !== 0 ? Math.abs(cashBank / liability) : 0;
	}

	public getCurrentRatio(
		currentAssetDatas: DimensionData[],
		currentLiabilityDatas: DimensionData[],
	) {
		const asset = this.getDatas(currentAssetDatas);
		const liability = this.getDatas(currentLiabilityDatas);

		return liability !== 0 ? Math.abs(asset / liability) : 0;
	}

	public getDebtRatio(
		totalAssetDatas: DimensionData[],
		totalLiabilityDatas: DimensionData[],
	) {
		const asset = this.getDatas(totalAssetDatas);
		const liability = this.getDatas(totalLiabilityDatas);

		return asset !== 0 ? Math.abs(liability / asset) : 0;
	}

	public async getReturnOnEquity(
		dateRange: [number, number],
		costDatas: DimensionData[],
		incomeDatas: DimensionData[],
		equityDatas: DimensionData[],
		expenseDatas: DimensionData[],
	) {
		const netProfitBeforeTax = await this.getNetProfitBeforeTax(
			dateRange,
			incomeDatas,
			costDatas,
			expenseDatas,
		);

		const equity = this.getDatas(equityDatas);

		return equity !== 0 ? (netProfitBeforeTax / equity) * 100 * -1 : 0;
	}

	public async getLeverageRatio(
		dateRange: [number, number],
		liabilityDatas: DimensionData[],
		equityDatas: DimensionData[],
	) {
		const equity = await this.getEquity(dateRange, equityDatas);
		const liability = this.getDatas(liabilityDatas);

		return equity !== 0 ? liability / equity : 0;
	}

	public getFixedAssetToNetRatio(
		assetDatas: DimensionData[],
		liabilityDatas: DimensionData[],
	) {
		const fixedAsset = this.getDatas(assetDatas, ['FA']);
		const totalAsset = this.getDatas(assetDatas);
		const totalLiability = this.getDatas(liabilityDatas);
		const net = totalAsset - Math.abs(totalLiability);

		return net !== 0 ? fixedAsset / net : 0;
	}

	public getCurrentLiabilityToNetRatio(
		assetDatas: DimensionData[],
		liabilityDatas: DimensionData[],
	) {
		const currentLiability = this.getDatas(liabilityDatas, ['CL']);
		const totalAsset = this.getDatas(assetDatas);
		const totalLiability = this.getDatas(liabilityDatas);
		const net = totalAsset - Math.abs(totalLiability);

		return net !== 0 ? Math.abs(currentLiability) / net : 0;
	}

	public getWorkingCapitalToTotalAssetRatio(
		assetDatas: DimensionData[],
		liabilityDatas: DimensionData[],
	) {
		const currentLiability = this.getDatas(liabilityDatas, ['CL']);

		const currentAsset = this.getDatas(assetDatas, ['CA']);

		const totalAsset = this.getDatas(assetDatas);

		const net = currentAsset - Math.abs(currentLiability);

		return totalAsset !== 0 ? (net / totalAsset) * 100 : 0;
	}

	public async getInventoryTurnover(
		dateRange: [number, number],
		costDatas: DimensionData[],
	) {
		const cost = await this.getCost(dateRange, costDatas);

		const stockBalance = await FilteredDatabase.getStockMovement2(dateRange);

		const result = stockBalance !== 0 ? cost / stockBalance : 0;
		return result;
	}

	public async getTotalAssetTurnover(
		dateRange: [number, number],
		incomeDatas: DimensionData[],
		beforeAssetDatas: DimensionData[],
		afterAssetDatas: DimensionData[],
	) {
		// const dayBefore = dateRange[0] - 1;

		const netSales = await this.getNetSales(incomeDatas);

		const assetBefore = this.getDatas(beforeAssetDatas);

		const assetAfter = this.getDatas(afterAssetDatas);

		const stockBalance = await FilteredDatabase.getStockMovement2(dateRange);

		const averageAsset = (assetBefore + assetAfter) / 2 + stockBalance;

		const result = averageAsset !== 0 ? netSales / averageAsset : 0;
		return result;
	}

	public async getEfficiencyRatio(
		expenseDatas: DimensionData[],
		incomeDatas: DimensionData[],
	) {
		const netSales = await this.getNetSales(incomeDatas);
		const expenses = this.getDatas(expenseDatas, ['EP']);

		const result = netSales !== 0 ? (expenses / netSales) * 100 : 0;

		return result;
	}

	public async getAccountReceivableTurnOver(
		incomeDatas: DimensionData[],
		debtorDatas: DimensionData[],
	) {
		const netSales = await this.getNetSales(incomeDatas);

		const debtors = this.getDatas(debtorDatas);

		const result = debtors !== 0 ? netSales / debtors : 0;

		return result;
	}

	public async getAccountPayableTurnOver(
		incomeDatas: DimensionData[],
		creditorDatas: DimensionData[],
	) {
		const netSales = await this.getNetSales(incomeDatas);

		const creditors = this.getDatas(creditorDatas);

		const result = creditors !== 0 ? Math.abs(netSales / creditors) : 0;

		return result;
	}

	public get expensiveHook() {
		const {
			currentYearDateRange,
			lastYearDateRange,
			last2YearDateRange,
			last3YearDateRange,
			last4YearDateRange,
		} = this;
		return JSON.stringify([
			currentYearDateRange,
			lastYearDateRange,
			last2YearDateRange,
			last3YearDateRange,
			last4YearDateRange,
		]);
	}

	public async expensiveCalc() {
		// Current year ref

		const currentYearDateRangeRef = FilteredDatabase.ref(
			'transactions',
		).dateRange(this.currentYearDateRange);

		const currentYearAsOfRef = FilteredDatabase.ref('transactions').dateAsOf(
			this.currentYearDateRange[1],
		);

		const currentYearBeforeFinancialStartRef = FilteredDatabase.ref(
			'transactions',
		).dateAsOf(this.currentYearDateRange[0] - 1);

		// Year 1 ref

		const lastYearDateRangeRef = FilteredDatabase.ref('transactions').dateRange(
			this.lastYearDateRange,
		);

		const lastYearAsOfRef = FilteredDatabase.ref('transactions').dateAsOf(
			this.lastYearDateRange[1],
		);

		const lastYearBeforeFinancialStartRef = FilteredDatabase.ref(
			'transactions',
		).dateAsOf(this.lastYearDateRange[0] - 1);

		// Year 2 ref

		const last2YearDateRangeRef = FilteredDatabase.ref(
			'transactions',
		).dateRange(this.last2YearDateRange);

		const last2YearAsOfRef = FilteredDatabase.ref('transactions').dateAsOf(
			this.last2YearDateRange[1],
		);

		const last2YearBeforeFinancialStartRef = FilteredDatabase.ref(
			'transactions',
		).dateAsOf(this.last2YearDateRange[0] - 1);

		// Year 3 ref

		const last3YearDateRangeRef = FilteredDatabase.ref(
			'transactions',
		).dateRange(this.last3YearDateRange);

		const last3YearAsOfRef = FilteredDatabase.ref('transactions').dateAsOf(
			this.last3YearDateRange[1],
		);

		const last3YearBeforeFinancialStartRef = FilteredDatabase.ref(
			'transactions',
		).dateAsOf(this.last3YearDateRange[0] - 1);

		// Year 4 ref

		const last4YearDateRangeRef = FilteredDatabase.ref(
			'transactions',
		).dateRange(this.last4YearDateRange);

		const last4YearAsOfRef = FilteredDatabase.ref('transactions').dateAsOf(
			this.last4YearDateRange[1],
		);

		const last4YearBeforeFinancialStartRef = FilteredDatabase.ref(
			'transactions',
		).dateAsOf(this.last4YearDateRange[0] - 1);

		// Year 4 ref

		const last5YearDateRangeRef = FilteredDatabase.ref(
			'transactions',
		).dateRange(this.last5YearDateRange);

		const incomeAccTypeList: string[] = ['SL', 'SA', 'OI', 'EO'];
		const incomeAccTypeNameList: string[] = [
			'SALES',
			'SALES ADJUSTMENT',
			'OTHER INCOME',
			'',
		];

		const cashBankAccTypeList: string[] = ['CH', 'BA'];
		const cashBankAccTypeNameList: string[] = ['CASHES', 'BANKS'];

		const assetsAccTypeList: string[] = ['CA', 'OA', 'FA'];
		const assetsAccTypeNameList: string[] = [
			'CURRENT ASSETS',
			'OTHER ASSETS',
			'FIXED ASSETS',
		];

		const liablityAccTypeList: string[] = ['CL', 'OL', 'LT'];
		const liablityAccTypeNameList: string[] = [
			'CURRENT LIABILITITES',
			'OTHER LIABILITITES',
			'NON-CURRENT LIABILITITES',
		];

		const equityAccTypeList: string[] = [
			'RE',
			'EP',
			'SL',
			'SA',
			'OI',
			'EO',
			'CO',
			'TX',
			'CP',
			'RV',
			'AP',
		];

		const equityAccTypeNameList: string[] = [
			'RETAINED EARNING',
			'EXPENSES',
			'SALES',
			'SALES ADJUSTMENT',
			'OTHER INCOME',
			'',
			'COST OF GOODS SOLD',
			'TAXATION',
			'EQUITY',
			'RESERVE',
			'',
		];

		// Dimension Map

		const equityMap: DimensionMap[] = equityAccTypeList.map((code, index) => ({
			filterType: 'string',
			filterKey: 'accType',
			value: [code],
			text: equityAccTypeNameList[index],
		}));

		const cashBankMap: DimensionMap[] = cashBankAccTypeList.map(
			(type, index) => ({
				filterType: 'string',
				filterKey: 'specialAccType',
				value: [type],
				text: cashBankAccTypeNameList[index],
			}),
		);

		const incomeMap: DimensionMap[] = incomeAccTypeList.map((code, index) => ({
			filterType: 'string',
			filterKey: 'accType',
			value: [code],
			text: incomeAccTypeNameList[index],
		}));

		const costMap: DimensionMap[] = [
			{
				filterType: 'string',
				filterKey: 'accType',
				value: ['CO'],
				text: 'COST OF GOODS SOLD',
			},
		];

		const expensesMap: DimensionMap[] = [
			{
				filterType: 'string',
				filterKey: 'accType',
				value: ['EP'],
				text: 'EXPENSES',
			},
		];

		const assetsMap: DimensionMap[] = assetsAccTypeList.map((type, index) => ({
			filterType: 'string',
			filterKey: 'accType',
			value: [type],
			text: assetsAccTypeNameList[index],
		}));

		const liabilitiesMap: DimensionMap[] = liablityAccTypeList.map(
			(type, index) => ({
				filterType: 'string',
				filterKey: 'accType',
				value: [type],
				text: liablityAccTypeNameList[index],
			}),
		);

		const debtorMap: DimensionMap[] = [
			{
				filterType: 'string',
				filterKey: 'specialAccType',
				value: ['DR'],
				text: 'DEBTORS',
			},
		];

		const creditorMap: DimensionMap[] = [
			{
				filterType: 'string',
				filterKey: 'specialAccType',
				value: ['CR'],
				text: 'CREDITORS',
			},
		];

		let pa = 0.015;
		let pb = 0;

		// Income ( Date Range ) DD
		const currentYearDateRangeIncomeDatas: DimensionData[] =
			await this._loadDimension(
				currentYearDateRangeRef,
				incomeMap,
				pa,
				pb,
				'amount',
			);

		pb += pa;
		pa = 0.015;

		const lastYearDateRangeIncomeDatas: DimensionData[] =
			await this._loadDimension(
				lastYearDateRangeRef,
				incomeMap,
				pa,
				pb,
				'amount',
			);

		pb += pa;
		pa = 0.015;

		const last2YearDateRangeIncomeDatas: DimensionData[] =
			await this._loadDimension(
				last2YearDateRangeRef,
				incomeMap,
				pa,
				pb,
				'amount',
			);

		pb += pa;
		pa = 0.015;

		const last3YearDateRangeIncomeDatas: DimensionData[] =
			await this._loadDimension(
				last3YearDateRangeRef,
				incomeMap,
				pa,
				pb,
				'amount',
			);

		pb += pa;
		pa = 0.015;

		const last4YearDateRangeIncomeDatas: DimensionData[] =
			await this._loadDimension(
				last4YearDateRangeRef,
				incomeMap,
				pa,
				pb,
				'amount',
			);

		pb += pa;
		pa = 0.015;

		const last5YearDateRangeIncomeDatas: DimensionData[] =
			await this._loadDimension(
				last5YearDateRangeRef,
				incomeMap,
				pa,
				pb,
				'amount',
			);

		// Cost ( Date Range ) DD

		pb += pa;
		pa = 0.015;

		const currentYearDateRangeCostDatas: DimensionData[] =
			await this._loadDimension(
				currentYearDateRangeRef,
				costMap,
				pa,
				pb,
				'amount',
			);

		pb += pa;
		pa = 0.015;

		const lastYearDateRangeCostDatas: DimensionData[] =
			await this._loadDimension(
				lastYearDateRangeRef,
				costMap,
				pa,
				pb,
				'amount',
			);

		pb += pa;
		pa = 0.015;

		const last2YearDateRangeCostDatas: DimensionData[] =
			await this._loadDimension(
				last2YearDateRangeRef,
				costMap,
				pa,
				pb,
				'amount',
			);

		pb += pa;
		pa = 0.015;

		const last3YearDateRangeCostDatas: DimensionData[] =
			await this._loadDimension(
				last3YearDateRangeRef,
				costMap,
				pa,
				pb,
				'amount',
			);

		pb += pa;
		pa = 0.015;

		const last4YearDateRangeCostDatas: DimensionData[] =
			await this._loadDimension(
				last4YearDateRangeRef,
				costMap,
				pa,
				pb,
				'amount',
			);

		// Expense ( Date Range ) DD

		pb += pa;
		pa = 0.015;

		const currentYearDateRangeExpenseDatas: DimensionData[] =
			await this._loadDimension(
				currentYearDateRangeRef,
				expensesMap,
				pa,
				pb,
				'amount',
			);

		pb += pa;
		pa = 0.015;

		const lastYearDateRangeExpenseDatas: DimensionData[] =
			await this._loadDimension(
				lastYearDateRangeRef,
				expensesMap,
				pa,
				pb,
				'amount',
			);

		pb += pa;
		pa = 0.015;

		const last2YearDateRangeExpenseDatas: DimensionData[] =
			await this._loadDimension(
				last2YearDateRangeRef,
				expensesMap,
				pa,
				pb,
				'amount',
			);

		pb += pa;
		pa = 0.015;

		const last3YearDateRangeExpenseDatas: DimensionData[] =
			await this._loadDimension(
				last3YearDateRangeRef,
				expensesMap,
				pa,
				pb,
				'amount',
			);

		pb += pa;
		pa = 0.015;

		const last4YearDateRangeExpenseDatas: DimensionData[] =
			await this._loadDimension(
				last4YearDateRangeRef,
				expensesMap,
				pa,
				pb,
				'amount',
			);

		// Total asset ( As Of ) DD
		pb += pa;
		pa = 0.015;

		const currentYearAsOfTotalAssetDatas: DimensionData[] =
			await this._loadDimension(
				currentYearAsOfRef,
				assetsMap,
				pa,
				pb,
				'amount',
			);

		pb += pa;
		pa = 0.015;

		const lastYearAsOfTotalAssetDatas: DimensionData[] =
			await this._loadDimension(lastYearAsOfRef, assetsMap, pa, pb, 'amount');

		pb += pa;
		pa = 0.015;

		const last2YearAsOfTotalAssetDatas: DimensionData[] =
			await this._loadDimension(last2YearAsOfRef, assetsMap, pa, pb, 'amount');

		pb += pa;
		pa = 0.015;

		const last3YearAsOfTotalAssetDatas: DimensionData[] =
			await this._loadDimension(last3YearAsOfRef, assetsMap, pa, pb, 'amount');

		pb += pa;
		pa = 0.015;

		const last4YearAsOfTotalAssetDatas: DimensionData[] =
			await this._loadDimension(last4YearAsOfRef, assetsMap, pa, pb, 'amount');

		// Total asset ( Before financial start date ) DD
		pb += pa;
		pa = 0.015;

		const currentYearBeforeFinancialStartTotalAssetDatas: DimensionData[] =
			await this._loadDimension(
				currentYearBeforeFinancialStartRef,
				assetsMap,
				pa,
				pb,
				'amount',
			);

		pb += pa;
		pa = 0.015;

		const lastYearBeforeFinancialStartTotalAssetDatas: DimensionData[] =
			await this._loadDimension(
				lastYearBeforeFinancialStartRef,
				assetsMap,
				pa,
				pb,
				'amount',
			);

		pb += pa;
		pa = 0.015;

		const last2YearBeforeFinancialStartTotalAssetDatas: DimensionData[] =
			await this._loadDimension(
				last2YearBeforeFinancialStartRef,
				assetsMap,
				pa,
				pb,
				'amount',
			);

		pb += pa;
		pa = 0.015;

		const last3YearBeforeFinancialStartTotalAssetDatas: DimensionData[] =
			await this._loadDimension(
				last3YearBeforeFinancialStartRef,
				assetsMap,
				pa,
				pb,
				'amount',
			);

		pb += pa;
		pa = 0.015;

		const last4YearBeforeFinancialStartTotalAssetDatas: DimensionData[] =
			await this._loadDimension(
				last4YearBeforeFinancialStartRef,
				assetsMap,
				pa,
				pb,
				'amount',
			);

		// Total liability ( As Of ) DD

		pb += pa;
		pa = 0.015;

		const currentYearAsOfTotalLiabilityDatas: DimensionData[] =
			await this._loadDimension(
				currentYearAsOfRef,
				liabilitiesMap,
				pa,
				pb,
				'amount',
			);

		pb += pa;
		pa = 0.015;

		const lastYearAsOfTotalLiabilityDatas: DimensionData[] =
			await this._loadDimension(
				lastYearAsOfRef,
				liabilitiesMap,
				pa,
				pb,
				'amount',
			);

		pb += pa;
		pa = 0.015;

		const last2YearAsOfTotalLiabilityDatas: DimensionData[] =
			await this._loadDimension(
				last2YearAsOfRef,
				liabilitiesMap,
				pa,
				pb,
				'amount',
			);

		pb += pa;
		pa = 0.015;

		const last3YearAsOfTotalLiabilityDatas: DimensionData[] =
			await this._loadDimension(
				last3YearAsOfRef,
				liabilitiesMap,
				pa,
				pb,
				'amount',
			);

		pb += pa;
		pa = 0.015;

		const last4YearAsOfTotalLiabilityDatas: DimensionData[] =
			await this._loadDimension(
				last4YearAsOfRef,
				liabilitiesMap,
				pa,
				pb,
				'amount',
			);

		// Current asset ( As Of ) DD

		const currentYearAsOfCurrentAssetDatas: DimensionData[] =
			currentYearAsOfTotalAssetDatas.filter((dd) => dd.value[0] === 'CA');

		const lastYearAsOfCurrentAssetDatas: DimensionData[] =
			lastYearAsOfTotalAssetDatas.filter((dd) => dd.value[0] === 'CA');

		const last2YearAsOfCurrentAssetDatas: DimensionData[] =
			last2YearAsOfTotalAssetDatas.filter((dd) => dd.value[0] === 'CA');

		const last3YearAsOfCurrentAssetDatas: DimensionData[] =
			last3YearAsOfTotalAssetDatas.filter((dd) => dd.value[0] === 'CA');

		const last4YearAsOfCurrentAssetDatas: DimensionData[] =
			last4YearAsOfTotalAssetDatas.filter((dd) => dd.value[0] === 'CA');

		// Fixed asset ( As Of ) DD

		const currentYearAsOfFixedAssetDatas: DimensionData[] =
			currentYearAsOfTotalAssetDatas.filter((dd) => dd.value[0] === 'FA');

		const year1AsOfFixedAssetDatas: DimensionData[] =
			lastYearAsOfTotalAssetDatas.filter((dd) => dd.value[0] === 'FA');

		const last2YearAsOfFixedAssetDatas: DimensionData[] =
			last2YearAsOfTotalAssetDatas.filter((dd) => dd.value[0] === 'FA');

		const last3YearAsOfFixedAssetDatas: DimensionData[] =
			last3YearAsOfTotalAssetDatas.filter((dd) => dd.value[0] === 'FA');

		// Cash bank ( As Of ) DD

		pb += pa;
		pa = 0.015;

		const currentYearAsOfCashBankDatas: DimensionData[] =
			await this._loadDimension(
				currentYearAsOfRef,
				currentYearAsOfCurrentAssetDatas,
				pa,
				pb,
				(reff, paa, pbb) =>
					this._loadDimension(reff, cashBankMap, paa, pbb, 'amount'),
			);

		pb += pa;
		pa = 0.015;

		const lastYearAsOfCashBankDatas: DimensionData[] =
			await this._loadDimension(
				lastYearAsOfRef,
				lastYearAsOfCurrentAssetDatas,
				pa,
				pb,
				(reff, paa, pbb) =>
					this._loadDimension(reff, cashBankMap, paa, pbb, 'amount'),
			);

		pb += pa;
		pa = 0.015;

		const last2YearAsOfCashBankDatas: DimensionData[] =
			await this._loadDimension(
				last2YearAsOfRef,
				last2YearAsOfCurrentAssetDatas,
				pa,
				pb,
				(reff, paa, pbb) =>
					this._loadDimension(reff, cashBankMap, paa, pbb, 'amount'),
			);

		pb += pa;
		pa = 0.015;

		const last3YearAsOfCashBankDatas: DimensionData[] =
			await this._loadDimension(
				last3YearAsOfRef,
				last3YearAsOfCurrentAssetDatas,
				pa,
				pb,
				(reff, paa, pbb) =>
					this._loadDimension(reff, cashBankMap, paa, pbb, 'amount'),
			);

		pb += pa;
		pa = 0.015;

		const last4YearAsOfCashBankDatas: DimensionData[] =
			await this._loadDimension(
				last4YearAsOfRef,
				last4YearAsOfCurrentAssetDatas,
				pa,
				pb,
				(reff, paa, pbb) =>
					this._loadDimension(reff, cashBankMap, paa, pbb, 'amount'),
			);

		// Current liability ( As Of ) DD

		const currentYearAsOfCurrentLiabilityDatas: DimensionData[] =
			currentYearAsOfTotalLiabilityDatas.filter((dd) => dd.value[0] === 'CL');
		const lastYearAsOfCurrentLiabilityDatas: DimensionData[] =
			lastYearAsOfTotalLiabilityDatas.filter((dd) => dd.value[0] === 'CL');
		const last2YearAsOfCurrentLiabilityDatas: DimensionData[] =
			last2YearAsOfTotalLiabilityDatas.filter((dd) => dd.value[0] === 'CL');
		const last3YearAsOfCurrentLiabilityDatas: DimensionData[] =
			last3YearAsOfTotalLiabilityDatas.filter((dd) => dd.value[0] === 'CL');
		const last4YearAsOfCurrentLiabilityDatas: DimensionData[] =
			last4YearAsOfTotalLiabilityDatas.filter((dd) => dd.value[0] === 'CL');

		// Creditor ( As Of ) DD

		pb += pa;
		pa = 0.015;

		const currentYearAsOfCreditorDatas: DimensionData[] =
			await this._loadDimension(
				currentYearAsOfRef,
				currentYearAsOfCurrentLiabilityDatas,
				pa,
				pb,
				(reff, paa, pbb) =>
					this._loadDimension(reff, creditorMap, paa, pbb, 'amount'),
			);

		pb += pa;
		pa = 0.015;

		const lastYearAsOfCreditorDatas: DimensionData[] =
			await this._loadDimension(
				lastYearAsOfRef,
				lastYearAsOfCurrentLiabilityDatas,
				pa,
				pb,
				(reff, paa, pbb) =>
					this._loadDimension(reff, creditorMap, paa, pbb, 'amount'),
			);

		pb += pa;
		pa = 0.015;

		const last2YearAsOfCreditorDatas: DimensionData[] =
			await this._loadDimension(
				last2YearAsOfRef,
				last2YearAsOfCurrentLiabilityDatas,
				pa,
				pb,
				(reff, paa, pbb) =>
					this._loadDimension(reff, creditorMap, paa, pbb, 'amount'),
			);

		pb += pa;
		pa = 0.015;

		const last3YearAsOfCreditorDatas: DimensionData[] =
			await this._loadDimension(
				last3YearAsOfRef,
				last3YearAsOfCurrentLiabilityDatas,
				pa,
				pb,
				(reff, paa, pbb) =>
					this._loadDimension(reff, creditorMap, paa, pbb, 'amount'),
			);

		pb += pa;
		pa = 0.015;

		const last4YearAsOfCreditorDatas: DimensionData[] =
			await this._loadDimension(
				last4YearAsOfRef,
				last4YearAsOfCurrentLiabilityDatas,
				pa,
				pb,
				(reff, paa, pbb) =>
					this._loadDimension(reff, creditorMap, paa, pbb, 'amount'),
			);

		// Debtor ( As Of ) DD

		pb += pa;
		pa = 0.015;

		const currentYearAsOfDebtorDatas: DimensionData[] =
			await this._loadDimension(
				currentYearAsOfRef,
				currentYearAsOfCurrentAssetDatas,
				pa,
				pb,
				(reff, paa, pbb) =>
					this._loadDimension(reff, debtorMap, paa, pbb, 'amount'),
			);

		pb += pa;
		pa = 0.015;

		const lastYearAsOfDebtorDatas: DimensionData[] = await this._loadDimension(
			lastYearAsOfRef,
			lastYearAsOfCurrentAssetDatas,
			pa,
			pb,
			(reff, paa, pbb) =>
				this._loadDimension(reff, debtorMap, paa, pbb, 'amount'),
		);

		pb += pa;
		pa = 0.015;

		const last2YearAsOfDebtorDatas: DimensionData[] = await this._loadDimension(
			last2YearAsOfRef,
			last2YearAsOfCurrentAssetDatas,
			pa,
			pb,
			(reff, paa, pbb) =>
				this._loadDimension(reff, debtorMap, paa, pbb, 'amount'),
		);

		pb += pa;
		pa = 0.015;

		const last3YearAsOfDebtorDatas: DimensionData[] = await this._loadDimension(
			last3YearAsOfRef,
			last3YearAsOfCurrentAssetDatas,
			pa,
			pb,
			(reff, paa, pbb) =>
				this._loadDimension(reff, debtorMap, paa, pbb, 'amount'),
		);

		pb += pa;
		pa = 0.015;

		const last4YearAsOfDebtorDatas: DimensionData[] = await this._loadDimension(
			last4YearAsOfRef,
			last4YearAsOfCurrentAssetDatas,
			pa,
			pb,
			(reff, paa, pbb) =>
				this._loadDimension(reff, debtorMap, paa, pbb, 'amount'),
		);

		// Total equity ( As Of ) DD

		pb += pa;
		pa = 0.015;

		const currentYearAsOfTotalEquityDatas: DimensionData[] =
			await this._loadDimension(
				currentYearAsOfRef,
				equityMap,
				pa,
				pb,
				'amount',
			);

		pb += pa;
		pa = 0.015;

		const lastYearAsOfTotalEquityDatas: DimensionData[] =
			await this._loadDimension(lastYearAsOfRef, equityMap, pa, pb, 'amount');

		pb += pa;
		pa = 0.015;

		const last2YearAsOfTotalEquityDatas: DimensionData[] =
			await this._loadDimension(last2YearAsOfRef, equityMap, pa, pb, 'amount');

		pb += pa;
		pa = 0.015;

		const last3YearAsOfTotalEquityDatas: DimensionData[] =
			await this._loadDimension(last3YearAsOfRef, equityMap, pa, pb, 'amount');

		pb += pa;
		pa = 0.015;

		const last4YearAsOfTotalEquityDatas: DimensionData[] =
			await this._loadDimension(last4YearAsOfRef, equityMap, pa, pb, 'amount');

		this.generateLoadingText(1);

		const currentYearList = {} as FinancialPerformance;
		const lastYearList = {} as FinancialPerformance;
		const last2YearList = {} as FinancialPerformance;
		const last3YearList = {} as FinancialPerformance;
		const last4YearList = {} as FinancialPerformance;

		// Year

		currentYearList.year = moment(this.currentYearDateRange[1]).year();
		lastYearList.year = moment(this.lastYearDateRange[1]).year();
		last2YearList.year = moment(this.last2YearDateRange[1]).year();
		last3YearList.year = moment(this.last3YearDateRange[1]).year();
		last4YearList.year = moment(this.last4YearDateRange[1]).year();

		// Revenue Growth

		lastYearList.revenueGrowth = this.getRevenueGrowth(
			lastYearDateRangeIncomeDatas,
			last2YearDateRangeIncomeDatas,
		);

		last2YearList.revenueGrowth = this.getRevenueGrowth(
			last2YearDateRangeIncomeDatas,
			last3YearDateRangeIncomeDatas,
		);

		last3YearList.revenueGrowth = this.getRevenueGrowth(
			last3YearDateRangeIncomeDatas,
			last4YearDateRangeIncomeDatas,
		);

		last4YearList.revenueGrowth = this.getRevenueGrowth(
			last4YearDateRangeIncomeDatas,
			last5YearDateRangeIncomeDatas,
		);

		// Gross Margin

		currentYearList.grossMargin = await this.getGrossMargin(
			this.currentYearDateRange,
			currentYearDateRangeIncomeDatas,
			currentYearDateRangeCostDatas,
		);

		lastYearList.grossMargin = await this.getGrossMargin(
			this.lastYearDateRange,
			lastYearDateRangeIncomeDatas,
			lastYearDateRangeCostDatas,
		);

		last2YearList.grossMargin = await this.getGrossMargin(
			this.last2YearDateRange,
			last2YearDateRangeIncomeDatas,
			last2YearDateRangeCostDatas,
		);

		last3YearList.grossMargin = await this.getGrossMargin(
			this.last3YearDateRange,
			last3YearDateRangeIncomeDatas,
			last3YearDateRangeCostDatas,
		);

		last4YearList.grossMargin = await this.getGrossMargin(
			this.last4YearDateRange,
			last4YearDateRangeIncomeDatas,
			last4YearDateRangeCostDatas,
		);

		// Net Margin

		currentYearList.netMargin = await this.getNetMargin(
			this.currentYearDateRange,
			currentYearDateRangeIncomeDatas,
			currentYearDateRangeCostDatas,
			currentYearDateRangeExpenseDatas,
		);

		lastYearList.netMargin = await this.getNetMargin(
			this.lastYearDateRange,
			lastYearDateRangeIncomeDatas,
			lastYearDateRangeCostDatas,
			lastYearDateRangeExpenseDatas,
		);

		last2YearList.netMargin = await this.getNetMargin(
			this.last2YearDateRange,
			last2YearDateRangeIncomeDatas,
			last2YearDateRangeCostDatas,
			last2YearDateRangeExpenseDatas,
		);

		last3YearList.netMargin = await this.getNetMargin(
			this.last3YearDateRange,
			last3YearDateRangeIncomeDatas,
			last3YearDateRangeCostDatas,
			last3YearDateRangeExpenseDatas,
		);

		last4YearList.netMargin = await this.getNetMargin(
			this.last4YearDateRange,
			last4YearDateRangeIncomeDatas,
			last4YearDateRangeCostDatas,
			last4YearDateRangeExpenseDatas,
		);

		// Cash ratio

		currentYearList.cashRatio = await this.getCashRatio(
			currentYearAsOfCashBankDatas,
			currentYearAsOfCurrentLiabilityDatas,
		);

		lastYearList.cashRatio = await this.getCashRatio(
			lastYearAsOfCashBankDatas,
			lastYearAsOfCurrentLiabilityDatas,
		);

		last2YearList.cashRatio = await this.getCashRatio(
			last2YearAsOfCashBankDatas,
			last2YearAsOfCurrentLiabilityDatas,
		);

		last3YearList.cashRatio = await this.getCashRatio(
			last3YearAsOfCashBankDatas,
			last3YearAsOfCurrentLiabilityDatas,
		);

		last4YearList.cashRatio = await this.getCashRatio(
			last4YearAsOfCashBankDatas,
			last4YearAsOfCurrentLiabilityDatas,
		);

		// Current ratio

		currentYearList.currentRatio = await this.getCurrentRatio(
			currentYearAsOfCurrentAssetDatas,
			currentYearAsOfCurrentLiabilityDatas,
		);

		lastYearList.currentRatio = await this.getCurrentRatio(
			lastYearAsOfCurrentAssetDatas,
			lastYearAsOfCurrentLiabilityDatas,
		);

		last2YearList.currentRatio = await this.getCurrentRatio(
			last2YearAsOfCurrentAssetDatas,
			last2YearAsOfCurrentLiabilityDatas,
		);

		last3YearList.currentRatio = await this.getCurrentRatio(
			last3YearAsOfCurrentAssetDatas,
			last3YearAsOfCurrentLiabilityDatas,
		);

		last4YearList.currentRatio = await this.getCurrentRatio(
			last4YearAsOfCurrentAssetDatas,
			last4YearAsOfCurrentLiabilityDatas,
		);

		// Debt ratio

		currentYearList.debtRatio = await this.getDebtRatio(
			currentYearAsOfTotalAssetDatas,
			currentYearAsOfTotalLiabilityDatas,
		);

		lastYearList.debtRatio = await this.getDebtRatio(
			lastYearAsOfTotalAssetDatas,
			lastYearAsOfTotalLiabilityDatas,
		);

		last2YearList.debtRatio = await this.getDebtRatio(
			last2YearAsOfTotalAssetDatas,
			last2YearAsOfTotalLiabilityDatas,
		);

		last3YearList.debtRatio = await this.getDebtRatio(
			last3YearAsOfTotalAssetDatas,
			last3YearAsOfTotalLiabilityDatas,
		);

		last4YearList.debtRatio = await this.getDebtRatio(
			last4YearAsOfTotalAssetDatas,
			last4YearAsOfTotalLiabilityDatas,
		);

		// Return On Equity

		currentYearList.returnOnEquity = await this.getReturnOnEquity(
			this.currentYearDateRange,
			currentYearDateRangeCostDatas,
			currentYearDateRangeIncomeDatas,
			currentYearAsOfTotalEquityDatas,
			currentYearDateRangeExpenseDatas,
		);

		lastYearList.returnOnEquity = await this.getReturnOnEquity(
			this.lastYearDateRange,
			lastYearDateRangeCostDatas,
			lastYearDateRangeIncomeDatas,
			lastYearAsOfTotalEquityDatas,
			lastYearDateRangeExpenseDatas,
		);

		last2YearList.returnOnEquity = await this.getReturnOnEquity(
			this.last2YearDateRange,
			last2YearDateRangeCostDatas,
			last2YearDateRangeIncomeDatas,
			last2YearAsOfTotalEquityDatas,
			last2YearDateRangeExpenseDatas,
		);

		last3YearList.returnOnEquity = await this.getReturnOnEquity(
			this.last3YearDateRange,
			last3YearDateRangeCostDatas,
			last3YearDateRangeIncomeDatas,
			last3YearAsOfTotalEquityDatas,
			last3YearDateRangeExpenseDatas,
		);

		last4YearList.returnOnEquity = await this.getReturnOnEquity(
			this.last4YearDateRange,
			last4YearDateRangeCostDatas,
			last4YearDateRangeIncomeDatas,
			last4YearAsOfTotalEquityDatas,
			last4YearDateRangeExpenseDatas,
		);

		// Leverage ratio

		currentYearList.leverageRatio = await this.getLeverageRatio(
			this.currentYearDateRange,
			currentYearAsOfTotalLiabilityDatas,
			currentYearAsOfTotalEquityDatas,
		);

		lastYearList.leverageRatio = await this.getLeverageRatio(
			this.lastYearDateRange,
			lastYearAsOfTotalLiabilityDatas,
			lastYearAsOfTotalEquityDatas,
		);

		last2YearList.leverageRatio = await this.getLeverageRatio(
			this.last2YearDateRange,
			last2YearAsOfTotalLiabilityDatas,
			last2YearAsOfTotalEquityDatas,
		);

		last3YearList.leverageRatio = await this.getLeverageRatio(
			this.last3YearDateRange,
			last3YearAsOfTotalLiabilityDatas,
			last3YearAsOfTotalEquityDatas,
		);

		last4YearList.leverageRatio = await this.getLeverageRatio(
			this.last4YearDateRange,
			last4YearAsOfTotalLiabilityDatas,
			last4YearAsOfTotalEquityDatas,
		);

		// Fixed Assets to Net Worth Ratio

		currentYearList.fixedAssetToNetRatio = await this.getFixedAssetToNetRatio(
			currentYearAsOfTotalAssetDatas,
			currentYearAsOfTotalLiabilityDatas,
		);

		lastYearList.fixedAssetToNetRatio = await this.getFixedAssetToNetRatio(
			lastYearAsOfTotalAssetDatas,
			lastYearAsOfTotalLiabilityDatas,
		);

		last2YearList.fixedAssetToNetRatio = await this.getFixedAssetToNetRatio(
			last2YearAsOfTotalAssetDatas,
			last2YearAsOfTotalLiabilityDatas,
		);

		last3YearList.fixedAssetToNetRatio = await this.getFixedAssetToNetRatio(
			last3YearAsOfTotalAssetDatas,
			last3YearAsOfTotalLiabilityDatas,
		);

		last4YearList.fixedAssetToNetRatio = await this.getFixedAssetToNetRatio(
			last4YearAsOfTotalAssetDatas,
			last4YearAsOfTotalLiabilityDatas,
		);

		// Current liability to Net Worth Ratio

		currentYearList.currentLiabilitiesToNetWorth =
			await this.getCurrentLiabilityToNetRatio(
				currentYearAsOfTotalAssetDatas,
				currentYearAsOfTotalLiabilityDatas,
			);

		lastYearList.currentLiabilitiesToNetWorth =
			await this.getCurrentLiabilityToNetRatio(
				lastYearAsOfTotalAssetDatas,
				lastYearAsOfTotalLiabilityDatas,
			);

		last2YearList.currentLiabilitiesToNetWorth =
			await this.getCurrentLiabilityToNetRatio(
				last2YearAsOfTotalAssetDatas,
				last2YearAsOfTotalLiabilityDatas,
			);

		last3YearList.currentLiabilitiesToNetWorth =
			await this.getCurrentLiabilityToNetRatio(
				last3YearAsOfTotalAssetDatas,
				last3YearAsOfTotalLiabilityDatas,
			);

		last4YearList.currentLiabilitiesToNetWorth =
			await this.getCurrentLiabilityToNetRatio(
				last4YearAsOfTotalAssetDatas,
				last4YearAsOfTotalLiabilityDatas,
			);

		// Working Capital to total asset

		currentYearList.workingCapitalToTotalAsset =
			await this.getWorkingCapitalToTotalAssetRatio(
				currentYearAsOfTotalAssetDatas,
				currentYearAsOfTotalLiabilityDatas,
			);

		lastYearList.workingCapitalToTotalAsset =
			await this.getWorkingCapitalToTotalAssetRatio(
				lastYearAsOfTotalAssetDatas,
				lastYearAsOfTotalLiabilityDatas,
			);

		last2YearList.workingCapitalToTotalAsset =
			await this.getWorkingCapitalToTotalAssetRatio(
				last2YearAsOfTotalAssetDatas,
				last2YearAsOfTotalLiabilityDatas,
			);

		last3YearList.workingCapitalToTotalAsset =
			await this.getWorkingCapitalToTotalAssetRatio(
				last3YearAsOfTotalAssetDatas,
				last3YearAsOfTotalLiabilityDatas,
			);

		last4YearList.workingCapitalToTotalAsset =
			await this.getWorkingCapitalToTotalAssetRatio(
				last4YearAsOfTotalAssetDatas,
				last4YearAsOfTotalLiabilityDatas,
			);

		// Inventory turnover

		lastYearList.inventoryTurnover = await this.getInventoryTurnover(
			this.lastYearDateRange,
			lastYearDateRangeCostDatas,
		);

		last2YearList.inventoryTurnover = await this.getInventoryTurnover(
			this.last2YearDateRange,
			last2YearDateRangeCostDatas,
		);

		last3YearList.inventoryTurnover = await this.getInventoryTurnover(
			this.last3YearDateRange,
			last3YearDateRangeCostDatas,
		);

		last4YearList.inventoryTurnover = await this.getInventoryTurnover(
			this.last4YearDateRange,
			last4YearDateRangeCostDatas,
		);

		// Total asset turnover

		currentYearList.totalAssetTurnover = await this.getTotalAssetTurnover(
			this.currentYearDateRange,
			currentYearDateRangeIncomeDatas,
			currentYearBeforeFinancialStartTotalAssetDatas,
			currentYearAsOfTotalAssetDatas,
		);

		lastYearList.totalAssetTurnover = await this.getTotalAssetTurnover(
			this.lastYearDateRange,
			lastYearDateRangeIncomeDatas,
			lastYearBeforeFinancialStartTotalAssetDatas,
			lastYearAsOfTotalAssetDatas,
		);

		last2YearList.totalAssetTurnover = await this.getTotalAssetTurnover(
			this.last2YearDateRange,
			last2YearDateRangeIncomeDatas,
			last2YearBeforeFinancialStartTotalAssetDatas,
			last2YearAsOfTotalAssetDatas,
		);

		last3YearList.totalAssetTurnover = await this.getTotalAssetTurnover(
			this.last3YearDateRange,
			last3YearDateRangeIncomeDatas,
			last3YearBeforeFinancialStartTotalAssetDatas,
			last3YearAsOfTotalAssetDatas,
		);

		last4YearList.totalAssetTurnover = await this.getTotalAssetTurnover(
			this.last4YearDateRange,
			last4YearDateRangeIncomeDatas,
			last4YearBeforeFinancialStartTotalAssetDatas,
			last4YearAsOfTotalAssetDatas,
		);

		// Efficiency Ratio

		currentYearList.efficiencyRatio = await this.getEfficiencyRatio(
			currentYearDateRangeExpenseDatas,
			currentYearDateRangeIncomeDatas,
		);

		lastYearList.efficiencyRatio = await this.getEfficiencyRatio(
			lastYearDateRangeExpenseDatas,
			lastYearDateRangeIncomeDatas,
		);

		last2YearList.efficiencyRatio = await this.getEfficiencyRatio(
			last2YearDateRangeExpenseDatas,
			last2YearDateRangeIncomeDatas,
		);

		last3YearList.efficiencyRatio = await this.getEfficiencyRatio(
			last3YearDateRangeExpenseDatas,
			last3YearDateRangeIncomeDatas,
		);

		last4YearList.efficiencyRatio = await this.getEfficiencyRatio(
			last4YearDateRangeExpenseDatas,
			last4YearDateRangeIncomeDatas,
		);

		// Account Receivable Turnover

		currentYearList.accountReceivableTurnover =
			await this.getAccountReceivableTurnOver(
				currentYearDateRangeIncomeDatas,
				currentYearAsOfDebtorDatas,
			);

		lastYearList.accountReceivableTurnover =
			await this.getAccountReceivableTurnOver(
				lastYearDateRangeIncomeDatas,
				lastYearAsOfDebtorDatas,
			);

		last2YearList.accountReceivableTurnover =
			await this.getAccountReceivableTurnOver(
				last2YearDateRangeIncomeDatas,
				last2YearAsOfDebtorDatas,
			);

		last3YearList.accountReceivableTurnover =
			await this.getAccountReceivableTurnOver(
				last3YearDateRangeIncomeDatas,
				last3YearAsOfDebtorDatas,
			);

		last4YearList.accountReceivableTurnover =
			await this.getAccountReceivableTurnOver(
				last4YearDateRangeIncomeDatas,
				last4YearAsOfDebtorDatas,
			);

		// Account Payable Turnover

		currentYearList.accountPayableTurnover =
			await this.getAccountPayableTurnOver(
				currentYearDateRangeIncomeDatas,
				currentYearAsOfCreditorDatas,
			);

		lastYearList.accountPayableTurnover = await this.getAccountPayableTurnOver(
			lastYearDateRangeIncomeDatas,
			lastYearAsOfCreditorDatas,
		);

		last2YearList.accountPayableTurnover = await this.getAccountPayableTurnOver(
			last2YearDateRangeIncomeDatas,
			last2YearAsOfCreditorDatas,
		);

		last3YearList.accountPayableTurnover = await this.getAccountPayableTurnOver(
			last3YearDateRangeIncomeDatas,
			last3YearAsOfCreditorDatas,
		);

		last4YearList.accountPayableTurnover = await this.getAccountPayableTurnOver(
			last4YearDateRangeIncomeDatas,
			last4YearAsOfCreditorDatas,
		);

		this.currentYearResult = currentYearList;
		this.lastYearResult = lastYearList;
		this.last2YearResult = last2YearList;
		this.last3YearResult = last3YearList;
		this.last4YearResult = last4YearList;

		this.saveHistory(
			'currentYearResult',
			'lastYearResult',
			'last2YearResult',
			'last3YearResult',
			'last4YearResult',
		);
	}

	public onHovering(value: boolean, filterId: string) {
		this.$emit('hovering', value, filterId);
	}
	public onEditing(value: boolean, filterId: string) {
		this.$emit('editing', value, filterId);
	}
}
